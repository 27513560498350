import pageSelector from "../external/pageSelector";
import LandingPage, {
  getStaticProps as landingPageProps,
} from "./landing-page-demo/[variant]";
import MenuPage, { getStaticProps as menuPageProps } from "./menu";

export const getStaticProps = async (context: any) => {
  const landingPageConfig = pageSelector.getLandingPage();
  if (landingPageConfig.enabled) {
    return landingPageProps(context);
  }
  return menuPageProps(context);
};

export default function Homepage(props: any) {
  const landingPageConfig = pageSelector.getLandingPage();
  if (landingPageConfig.enabled) {
    return <LandingPage {...props} />;
  }
  return <MenuPage {...props} />;
}
